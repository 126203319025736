.yellow-row, .updated-row.ant-table-cell-row-hover:hover {
    background-color: #FFFBA9 !important;
}

.yellow-row > td {
    background: unset !important;
}

.antd-updated-cell {
    background-color: #FFFFCC !important;
}

.green-row, .added-row.ant-table-cell-row-hover:hover {
    background-color: #E5F6DF !important;
}

.green-row > td {
    background: unset !important;
}

.disabled-row, .disabled-row.ant-table-cell-row-hover:hover {
    background-color: #EEE !important;
}

.disabled-row > td {
    background: unset !important;
}

.red-row, .deleted-row.ant-table-cell-row-hover:hover {
    background-color: #ffcccb !important;
}

.red-row > td {
    background: unset !important;
}