.production-calendar  
.ant-picker-calendar-header 
.ant-radio-group {
	display: none !important;
}

.production-calendar .ant-picker-panel{ 
	background-color: #F0F2F5 !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-panel-container, .ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important; 
}

.production-calendar > * .ant-picker-cell {
	cursor: default;
}